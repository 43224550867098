import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import ElementIcon, { Icons } from 'src/components/ElementIcon/ElementIcon';
import { useTheme } from 'src/hooks';

function ElementShareLinks({ pathAlias, title }) {
  const t = useTheme();
  const meta = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const baseUrl = meta.site.siteMetadata.siteUrl;
  const url = `${baseUrl}/${pathAlias}`;

  return (
    <Container>
      <Row>
        <Col xs="auto" className="d-flex align-items-center">
          <h3 className="text-size-xs text-uppercase text-muted mb-0 mr-5">{t.global.share}</h3>
        </Col>
        <Col xs="auto">
          <TwitterShareButton url={url} title={title}>
            <ElementIcon icon={Icons.twitter} colorVariant="gray" colorVariantHover="blue" />
          </TwitterShareButton>
        </Col>
        <Col xs="auto">
          <FacebookShareButton url={url}>
            <ElementIcon icon={Icons.facebook} colorVariant="gray" colorVariantHover="blue" />
          </FacebookShareButton>
        </Col>
        <Col xs="auto">
          <LinkedinShareButton url={url}>
            <ElementIcon icon={Icons.linkedin} colorVariant="gray" colorVariantHover="blue" />
          </LinkedinShareButton>
        </Col>
      </Row>
    </Container>
  );
}

ElementShareLinks.propTypes = {
  pathAlias: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ElementShareLinks;
